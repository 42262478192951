import {
  CtaLink,
  CtaLinkProps,
} from "design-system/components/primitives/cta-link/cta-link";
import { FC } from "react";

export const ExpandedMobileCTA: FC<CtaLinkProps> = (props) => {
  return (
    <CtaLink
      {...props}
      type="primary-button"
      className="hbs-local-navigation__mobile-cta"
    />
  );
};
