import { EditAttributes } from "design-system/types/types";
import { DataLayerEvent } from "frontend/hooks/use-data-layer";
import { AnchorHTMLAttributes, forwardRef, MouseEventHandler } from "react";

export interface DataOptions {
  parentTitle?: string;
  programName?: string;
  imageTitle?: string;
  searchTerm?: string;
  position?: number;
  searchCategory?: string;
}

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  editAttributes?: EditAttributes;
  dataLayer?: DataLayerEvent;
  dataParentTitle?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href,
      target,
      children,
      className,
      rel,
      onClick,
      editAttributes,
      dataLayer,
      ...props
    },
    ref,
  ) => {
    if (!target) target = "_blank";

    if (href?.startsWith("/") || href?.includes(".hbs.edu")) {
      if (href.includes("webassets.hbs.edu")) target = "_blank";
      else target = "_self";
    }

    const handleOnClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
      /* eslint-disable camelcase */
      if (
        dataLayer?.event_data.explore_search &&
        !dataLayer.event_data.explore_search_info_box_clicked
      ) {
        const title = typeof children === "string" ? children.trim() : "";
        dataLayer.event_data.explore_search_info_box_clicked = title;
      }
      /* eslint-enable camelcase */
      if (onClick) {
        // pushDataLayer gets called at the parent layer to prevent double firing
        onClick(event);
      }
    };

    return (
      // eslint-disable-next-line react/forbid-elements
      <a
        {...editAttributes}
        href={href}
        target={target}
        className={className}
        rel={rel}
        {...props}
        ref={ref}
        onClick={handleOnClick}
      >
        {children}
      </a>
    );
  },
);
