import "./cta-link.scss";

import { MouseEvent, ReactNode, forwardRef } from "react";
import cn from "clsx";
import { Icon, IconName } from "design-system/components/icons/icon";
import { EditAttributes } from "design-system/types/types";
import { Link } from "../link/link";
import { isDocumentLink } from "design-system/utils/document";
import { isExternalLink } from "design-system/utils/external";
import { DataLayerEvent, useDataLayer } from "frontend/hooks/use-data-layer";

export type CtaLinkType = "primary-button" | "secondary-button" | "link";

export interface CtaLinkProps {
  children?: ReactNode;
  href?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  dataLayer?: DataLayerEvent;
  type?: CtaLinkType;
  className?: string;
  isSmall?: boolean;
  isNested?: boolean;
  icon?: IconName;
  hideIcon?: boolean;
  "aria-label"?: string;
  id?: string;
  "aria-selected"?: boolean;
  "aria-describedby"?: string;
  tabIndex?: number;
  editAttributes?: EditAttributes;
}

/**
 * Simple link with icon used in various components
 *
 * - **`id: P-010-000-09`**
 */
export const CtaLink = forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  CtaLinkProps
>(
  (
    {
      children,
      className,
      href,
      onClick,
      dataLayer,
      type = "link",
      isSmall,
      isNested,
      icon,
      hideIcon,
      "aria-label": ariaLabel,
      "aria-selected": ariaSelected,
      "aria-describedby": ariaDescribedBy,
      id,
      tabIndex,
      editAttributes,
    },
    ref,
  ) => {
    let isExternal: boolean | undefined;

    if (href) {
      isExternal = isExternalLink(href);
    }

    // Initialize icon if not provided
    if (!icon && href) {
      icon = isDocumentLink(href) ? "download" : "arrow";
    }

    const { pushDataLayer } = useDataLayer();

    const ctaClassNames = cn(
      "hbs-cta-link",
      isSmall && "hbs-cta-link--small",
      `hbs-cta-link--${type}`,
      isNested ? "hbs-cta-link--nested" : "",
      className,
    );

    const iconClassNames = cn(
      "hbs-cta-link__icon",
      `hbs-cta-link__icon--${icon?.toLowerCase() || ""}`,
      isExternal && "hbs-cta-link__icon--external",
    );

    // Split string into first words & last word to make sure icon doesn't wrap on its own
    let lastWord;
    let firstWords;
    if (typeof children === "string") {
      const words = children.trim().split(" ");
      lastWord = words.pop();
      firstWords = words.join(" ");
    }

    const withoutSplitWords = (
      <span className="hbs-cta-link__text-nowrap">
        <span className="hbs-cta-link__text">{children}</span>
        {!hideIcon && icon && <Icon name={icon} className={iconClassNames} />}
      </span>
    );

    const commonProps = {
      className: ctaClassNames,
      ref,
      id,
      "aria-selected": ariaSelected,
      "aria-describedby": ariaDescribedBy,
      "aria-label": ariaLabel,
      tabIndex,
    };

    if (onClick) {
      if (dataLayer) {
        window.addEventListener("click", () => {
          pushDataLayer(dataLayer);
        });
      }

      return (
        <button {...editAttributes} {...commonProps} onClick={onClick}>
          {withoutSplitWords}
        </button>
      );
    }

    if (
      typeof children !== "string" ||
      type === "primary-button" ||
      type === "secondary-button"
    ) {
      let handleOnClick = undefined;
      if (dataLayer) {
        handleOnClick = () => {
          const title = typeof children === "string" ? children.trim() : "";
          if (
            dataLayer?.event_data &&
            dataLayer.event.includes("program_finder")
          ) {
            /* eslint-disable camelcase */
            dataLayer.event_data.program_action = dataLayer.event_data
              .program_action
              ? dataLayer.event_data.program_action
              : "program cta click";
            dataLayer.event_data.program_name = ariaLabel;
          }
          if (dataLayer.event_data.explore_search) {
            dataLayer.event_data.explore_search_info_box_clicked = title;
          }
          if (dataLayer.event === "info_box_item_click") {
            dataLayer.event_data.infobox_item_link_text = title;
            dataLayer.event_data.infobox_item_url = href;
          }
          /* eslint-enable camelcase */
          pushDataLayer(dataLayer);
        };
      }

      return (
        <Link
          editAttributes={editAttributes}
          {...commonProps}
          href={href}
          onClick={handleOnClick}
          dataLayer={dataLayer}
        >
          {withoutSplitWords}
        </Link>
      );
    }

    const withSplitWords = (
      <span className="hbs-cta-link__text">
        {firstWords}{" "}
        <span className="hbs-cta-link__text-last">
          {lastWord}
          {!hideIcon && icon && <Icon name={icon} className={iconClassNames} />}
        </span>
      </span>
    );

    if (isNested) {
      return (
        <span
          {...editAttributes}
          id={id}
          aria-describedby={ariaDescribedBy}
          className={ctaClassNames}
        >
          {withSplitWords}
        </span>
      );
    }

    return (
      <Link
        editAttributes={editAttributes}
        {...commonProps}
        href={href}
        dataLayer={dataLayer}
        aria-label={
          typeof children === "string" ? ariaLabel || children : undefined
        }
      >
        {withSplitWords}
      </Link>
    );
  },
);
