import { Link } from "design-system/components/primitives/link/link";
import { FC, RefObject } from "react";

export interface SiteHeaderWorkingKnowledgeTitle {
  sectionTitleRef: RefObject<HTMLDivElement>;
  workingKnowledgeTitleLink?: string;
}

export const SiteHeaderWorkingKnowledgeTitle: FC<
  SiteHeaderWorkingKnowledgeTitle
> = ({ sectionTitleRef, workingKnowledgeTitleLink }) => {
  return (
    <div className="hbs-site-header__section-title" ref={sectionTitleRef}>
      <Link
        className="hbs-site-header__section-title-link"
        href={workingKnowledgeTitleLink}
      >
        Working Knowledge
      </Link>
    </div>
  );
};
