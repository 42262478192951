import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import clsx from "clsx";
import { FC } from "react";
import { ExpandedNavigationLinkWithSubItems } from "../local/expanded-local-navigation";
import { FeatureLink } from "./feature-link";
import { MenuLink } from "./menu-link";
import { TopLink } from "./top-link";

interface PageListProps {
  pageList: ExpandedNavigationLinkWithSubItems;
  layout: "vertical" | "horizontal";
}

export type LinkType = "topLink" | "link" | "featureLink";

export const PageList: FC<PageListProps> = ({ pageList, layout }) => {
  const {
    featureLinksTitle,
    featureCard,
    featureDescription,
    featureLinks,
    featureMedia,
    menu,
    topLinks,
  } = pageList;

  const hasTopLinks = !!topLinks?.length;
  const hasMenu = !!menu?.length;
  const hasFeatureLinks = !!featureLinks?.length;

  const featureOptions = {
    featureCard,
    featureDescription,
    featureMedia,
  };

  return (
    <div className={`expanded-navigation-link-list-${layout}`}>
      <>
        {hasTopLinks && (
          <NavigationMenu.Item asChild>
            <NavigationMenu.List
              className={`expanded-navigation-top-links--${layout}`}
            >
              {topLinks.map((topLink) => (
                <TopLink key={topLink.title} {...topLink} />
              ))}
            </NavigationMenu.List>
          </NavigationMenu.Item>
        )}

        {hasMenu && (
          <NavigationMenu.List asChild>
            <ul className={`expanded-navigation-menu-${layout}`}>
              {menu.map((menuLink) => (
                <MenuLink {...menuLink} key={menuLink.title} />
              ))}
            </ul>
          </NavigationMenu.List>
        )}

        {hasFeatureLinks && (
          <div className="expanded-navigation-feature-wrapper">
            <NavigationMenu.List
              className={clsx(
                `expanded-navigation-feature-${layout}${featureCard ? "__has-card" : ""}`,
              )}
            >
              {featureLinksTitle && (
                <h5 className="expanded-navigation-content-links-label">
                  {featureLinksTitle}
                </h5>
              )}
              <div className="expanded-navigation-feature-items">
                {featureLinks.map((featureLink) => {
                  return (
                    <FeatureLink
                      {...featureLink}
                      key={featureLink.title}
                      featureOptions={featureOptions}
                    />
                  );
                })}
              </div>
            </NavigationMenu.List>
          </div>
        )}
      </>
    </div>
  );
};
