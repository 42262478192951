import { Link } from "design-system/components/primitives/link/link";
import { FC } from "react";
import { ExpandedLocalNavigationItemProps } from "../../local/expanded-local-navigation";
import { ExpandedMobileHorizontalPageGroup } from "./expanded-mobile-horizontal-page-group";

interface HorizontalLayoutProps {
  item: ExpandedLocalNavigationItemProps;
}

export const ExpandedMobileHorizontal: FC<HorizontalLayoutProps> = ({
  item,
}) => {
  const { title, linkTitle, url } = item;

  return (
    <>
      {url && (
        <li className="hbs-local-navigation__accordion-submenu-item hbs-local-navigation__accordion-submenu-item--primary">
          <Link
            href={url}
            className="hbs-local-navigation__accordion-submenu-item-link"
          >
            {linkTitle ? linkTitle : title}
          </Link>
        </li>
      )}
      {item.pageGroups?.map((pageGroup) => (
        <ExpandedMobileHorizontalPageGroup
          key={pageGroup.title}
          pageGroup={pageGroup}
        />
      ))}
    </>
  );
};
