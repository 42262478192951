import { filterUndefined } from "design-system/utils/filter-undefined";
import { FC } from "react";
import {
  ExpandedLocalNavigationItemProps,
  ExpandedNavigationLinkWithSubItems,
} from "../../local/expanded-local-navigation";
import { ExpandedMobileLink } from "../common/expanded-mobile-link";

interface VerticalLayoutProps {
  item: ExpandedLocalNavigationItemProps;
}

export const ExpandedMobileVertical: FC<VerticalLayoutProps> = ({ item }) =>
  item.pageGroups
    ?.map((pageGroup) =>
      pageGroup.pageListOrLinks?.map((pageListOrLink) => {
        if ("url" in pageListOrLink) {
          return (
            <ExpandedMobileLink
              {...pageListOrLink}
              key={pageListOrLink.title}
            />
          );
        }
        const pageList: ExpandedNavigationLinkWithSubItems = pageListOrLink;
        return [
          ...(pageList.topLinks ?? []),
          ...(pageList.menu ?? []),
          ...(pageList.featureLinks ?? []),
        ].map((item) => <ExpandedMobileLink {...item} key={item.title} />);
      }),
    )
    .flat(2)
    .filter(filterUndefined);
