import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { Link } from "design-system/components/primitives/link/link";
import {
  MediaAsset,
  MediaAssetProps,
} from "design-system/components/primitives/media-asset/media-asset";
import { FC, ReactNode } from "react";
import { NavigationLink } from "../../local-navigation/local-navigation";

interface FeatureLinkProps extends NavigationLink {
  mediaAsset?: MediaAssetProps;
  featureOptions?: {
    featureCard?: boolean;
    featureMedia?: boolean;
    featureDescription?: boolean;
  };
}

interface WrapperProps {
  url: string;
  className?: string;
  children: ReactNode;
}
const Wrapper: FC<WrapperProps> = ({ url, className, children }) => {
  return (
    <NavigationMenu.Item asChild>
      <NavigationMenu.Link
        asChild
        className="expanded-navigation-feature-item-link"
      >
        <Link href={url} className={className}>
          {children}
        </Link>
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
};

export const FeatureLink: FC<FeatureLinkProps> = ({
  title,
  description,
  url,
  featureOptions,
  mediaAsset,
}) => {
  if (!url) return null;

  const { featureCard, featureDescription, featureMedia } =
    featureOptions || {};

  const descriptionComponent = featureDescription && description && (
    <p className="expanded-navigation-feature-item-description">
      {description}
    </p>
  );

  if (featureCard) {
    return (
      <Wrapper url={url} className="expanded-navigation-feature-item-card">
        <h4 className="expanded-navigation-feature-item-card__title">
          {title}
        </h4>
        {descriptionComponent}
      </Wrapper>
    );
  }

  if (featureMedia && mediaAsset) {
    return (
      <Wrapper url={url} className="expanded-navigation-feature-item-media">
        <div className="expanded-navigation-feature-item-media__media">
          <MediaAsset {...mediaAsset} />
        </div>
        <div className="expanded-navigation-feature-item-media__content">
          <span className="expanded-navigation-feature-item-media__title">
            {title}
          </span>
          {descriptionComponent}
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper url={url}>
      <span className="expanded-navigation-feature-item__title">{title}</span>
      {descriptionComponent}
    </Wrapper>
  );
};
